import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Toaster from "../Basic/Toaster";

function Footer({ className }) {
  const navigate = useNavigate();

  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');

  const handleOnTOp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const submitSubscribeHandler = (e) => {
    e.preventDefault();

    setShowToaster(true);
    setToasterMessage('You are successfully subscribe to our newsletter.');
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <div className={className ? className : ''}>
        {/* main-footer */}
        <footer className="main-footer">
          <div className="footer-top">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-8 col-sm-12 footer-column">
                      <div className="footer-widget logo-widget">
                        <figure className="footer-logo"><a onClick={() => navigate('/')}><img width={180} src="https://togofogo.com/assets/images/logo.png" alt="" /></a></figure>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                      <div className="footer-widget links-widget">
                        <div className="widget-title">
                          <h3>Useful Link</h3>
                        </div>
                        <div className="widget-content">
                          <ul className="links-list clearfix">
                            <li><a href="https://www.xtracover.com/About-Us" target='_blank'>About Us</a></li>
                            <li><a onClick={() => navigate('/')}>Terms &amp; Conditions</a></li>
                            <li><a onClick={() => navigate('/')}>Privacy Policy</a></li>
                            <li><a href="tel:+91-8860396039">Contact Us</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget contact-widget">
                        <div className="widget-title">
                          <h3>Contact</h3>
                        </div>
                        <ul className="info-list clearfix">
                          <li>New Delhi, South Delhi
                            <br />India Pin-110020
                          </li>
                          <li><a href="tel:+91-8860396039">+91-8860396039</a></li>
                          <li><a href="mailto:togofogo@xtracover.com">togofogo@xtracover.com</a></li>
                        </ul>
                        <ul className="footer-social clearfix hide">
                          <li><a onClick={() => navigate('/')}><i className="fab fa-facebook-f" /></a></li>
                          <li><a onClick={() => navigate('/')}><i className="fab fa-instagram" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget newsletter-widget">
                        <div className="widget-title">
                          <h3>Newsletter</h3>
                        </div>
                        <div className="widget-content">
                          <p>New Delhi, South Delhi 110020</p>
                          <form onSubmit={(event) => submitSubscribeHandler(event)} className="newsletter-form">
                            <div className="form-group">
                              <input type="email" name="email" placeholder="Enter your email" required />
                              <button type="submit" className="theme-btn-two">Subscribe</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="auto-container clearfix">
              {/* <ul class="cart-list pull-left clearfix">
                  <li><a href="index.html"><img src="assets/images/resource/card-1.png" alt=""></a></li>
                  <li><a href="index.html"><img src="assets/images/resource/card-2.png" alt=""></a></li>
                  <li><a href="index.html"><img src="assets/images/resource/card-3.png" alt=""></a></li>
                  <li><a href="index.html"><img src="assets/images/resource/card-4.png" alt=""></a></li>
              </ul> */}
              <div className="copyright pull-right">
                <p><a onClick={() => navigate('/')}>Togofogo</a> © 2024 All Right Reserved</p>
              </div>
            </div>
          </div>
        </footer>
        {/* main-footer end */}
        {/*Scroll to top*/}
        <button className="scroll-top scroll-to-target" data-target="html" onClick={() => handleOnTOp()}>
          <i className="fas fa-long-arrow-alt-up" />
        </button>
      </div>
      <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
    </>
  );
}

export default Footer;
